import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROLES, withRolesGuard, WithRolesGuardProps } from '../../_utils/auth'

import AsAsync from '../../_utils/AsAsync.component'
import Loader from '../../_ui/Loader'

const AsyncProviderDashboard = AsAsync(() => import('../../_modules/provider-dashboard'))
const AsyncProviderBooking = AsAsync(() => import('../../_modules/provider-booking'))
const AsyncProviderReview = AsAsync(() => import('../../_modules/provider-review'))
const AsyncProviderInvoice = AsAsync(() => import('../../_modules/provider-invoice'))
const AsyncExamples = AsAsync(() => import('../../examples'))
const AsyncAffiliation = AsAsync(() => import('../../_modules/affiliation'))
const AsyncActivity = AsAsync(() => import('../../_modules/activity'))

const Routing = (props: WithRolesGuardProps) => {
  const {
    rolesGuard: { hasRoles, userLoading },
  } = props

  let defaultRoute = null
  if (!userLoading()) {
    if (hasRoles([ROLES.PROVIDER])) {
      defaultRoute = '/provider/dashboard'
    } else if (hasRoles([ROLES.AFFILIATE])) {
      defaultRoute = '/affiliation/dashboard'
    } else if (hasRoles([ROLES.INTERNAL_USER])) {
      // TODO add real defaultRoute for each of our internal profiles once we have them.
      //  but as of now, redirect to a bad page to avoid infinite redirections between this page and sign-up.
      defaultRoute = '/examples/dashboard'
    } else {
      defaultRoute = '/auth/sign-in'
    }
  }

  return (
    <Switch>
      <Route path={`/provider/dashboard`} component={AsyncProviderDashboard} />
      <Route path={`/provider/bookings`} component={AsyncProviderBooking} />
      <Route path={`/provider/reviews`} component={AsyncProviderReview} />
      <Route path={`/provider/invoices`} component={AsyncProviderInvoice} />

      <Route path={`/affiliation`} component={AsyncAffiliation} />

      <Route path={`/activities`} component={AsyncActivity} />

      {hasRoles([ROLES.INTERNAL_USER]) && <Route path={`/examples`} component={AsyncExamples} />}

      {defaultRoute && <Redirect to={defaultRoute} />}

      <Loader />
    </Switch>
  )
}

export default withRolesGuard()(Routing)
