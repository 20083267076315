import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Container, ListContainer, HideContainer, LoginTeaser, LoginTeaserIcon } from './SideBar.styled'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'

import Item from './Item'
import getItems from './getItems'

import { IActions, IUser } from './SideBar.container'

import { useTranslation } from 'react-i18next'

interface IProps {
  menuOpen: boolean
  actions: IActions
  user: IUser
}

const SideBar = (props: IProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const [showLearnMore, setShowLearnMore] = useState(false)
  const { menuOpen, actions, user } = props

  const displayFakeProviderSidebar =
    typeof user.data.roles === 'undefined' && history.location.pathname.startsWith('/provider/bookings/view/')
  const items = getItems(user, t, displayFakeProviderSidebar)

  const onClick = (path: string) => {
    history.push(path)
    if (window.innerWidth <= 1100) {
      actions.toggleMenu()
    }
  }

  return (
    <React.Fragment>
      {menuOpen && window.innerWidth <= 1100 && <HideContainer onClick={actions.toggleMenu} />}
      <Container open={menuOpen}>
        <ListContainer>
          {items.map(({ title, path, Icon, iconStyle }) => (
            <Item
              key={path}
              iconStyle={iconStyle}
              selected={pathname.startsWith(path)}
              onClick={() => onClick(path)}
              title={title}
              Icon={Icon}
            />
          ))}
          {displayFakeProviderSidebar && (
            <>
              <LoginTeaser
                onClick={() => {
                  setShowLearnMore(true)
                }}
              >
                <LoginTeaserIcon>
                  <LockOpenIcon />
                </LoginTeaserIcon>
                {t('SideBar.loginTeaser.text')} <u>{t('SideBar.loginTeaser.learnMore')}</u>
              </LoginTeaser>
              <Dialog
                open={showLearnMore}
                onClose={() => {
                  setShowLearnMore(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{t('SideBar.loginTeaser.dialogTitle')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('SideBar.loginTeaser.dialogText')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      history.push('/auth/forgot-password')
                    }}
                    color="primary"
                  >
                    {t('SideBar.loginTeaser.resetPassword')}
                  </Button>
                  <Button href="mailto:support@manawa.com" color="primary">
                    {t('SideBar.loginTeaser.contactUs')}
                  </Button>
                  <Button
                    onClick={() => {
                      setShowLearnMore(false)
                    }}
                    color="primary"
                  >
                    {t('close')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </ListContainer>
      </Container>
    </React.Fragment>
  )
}

export default SideBar
